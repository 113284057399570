import startup from "../.././assets/image/services/startup.png";
import growth from "../.././assets/image/services/growth.png";
import stability from "../.././assets/image/services/stability.png";
import saturation from "../.././assets/image/services/saturation.png";
import renuewal from "../.././assets/image/services/renuewal.png";
import renovation from "../.././assets/image/services/renovation.png";
import restructuring from "../.././assets/image/services/restructuring.png";

const collection = [
  startup,
  growth,
  stability,
  saturation,
  renuewal,
  restructuring,
  renovation,
];

export default collection;
